<template>
  <div>
    <div class="bar-element cursor-pointer" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
      <div class="d-flex justify-content-center">
        <img
            src="@/assets/images/icons/add-ico.svg"
            alt="Aggiungi"
            width="35"
        />
      </div>
      <p class="mt-2 mb-0 text-center c-blue fw-bolder text-uppercase">
        Crea Pratica
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "PracticesBar"
}
</script>

<style scoped>
  .bar-element {
    width: fit-content;
  }
</style>