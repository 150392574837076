
import {
  defineComponent,
  ref,
  reactive,
  computed,
  PropType,
  onBeforeUpdate,
} from 'vue';
import { Practice } from "@/types/Practice";
import SpinnerBlue from "@/components/spinners/SpinnerBlue.vue";

export default defineComponent({
  name: "Table",
  components: { SpinnerBlue },
  emits: [
    'actionView',
    'actionDelete',
  ],
  props: {
    headings: {
      type: Object,
      required: true
    },
    data: {
      type: Array as PropType<Practice[]>,
      required: true,
    },
    loading: Boolean as PropType<boolean>
  },
  setup(props, { emit }) {
    let tableHeadings = reactive<any>({});
    let tableData = ref<Practice[]>([]);
    let dataTableLength = computed(() => tableData.value.length);

    onBeforeUpdate(() => {
      tableHeadings.value = props.headings;
      tableData.value = props.data;
    });

    function hasOwn(object: Object, key: string): boolean {
      if (!object as Object instanceof Object) return false;

      return object.hasOwnProperty(key);
    }

    function iterates(index: number) {
      const headingKeys = Object.keys(tableHeadings.value);

      const data = headingKeys.map((key: string) => {
        /* @ts-ignore */
        return checkSpecialTypes(key, tableData.value[index][key]);
      });

      if (data.length !== headingKeys.length) {
        console.log('[ERROR] check');

        return [];
      }

      return data;
    }

    function checkSpecialTypes(key: string, element: string) {
      switch (key) {
        case 'email_verified_at':
          if (!element) return 'non verificata';

          return new Date(element).toLocaleDateString();
        case 'created_at':
        case 'updated_at':
          return new Date(element).toLocaleDateString();
        case 'status':
          return checkStatus(element);
      }

      return element
    }

    function checkStatus(status: string) {
      if (status.toUpperCase() === 'STARTED') {
        return `<div style="
                  position: relative;
                  top: 4px;
                  width: 1rem;
                  height: 1rem;
                  border-radius: 50%;
                  background-color: #f00"></div>`;
      }

      if (status.toUpperCase() === 'COMPLETED') {
        return `<div style="
                    position: relative;
                    top: 4px;
                    width: 1rem;
                    height: 1rem;
                    border-radius: 50%;
                    background-color: #0ff000"></div>`;
      }

      if (status.toUpperCase() === 'PROCESSING') {
        return `<div style="
                    position: relative;
                    top: 4px;
                    width: 1rem;
                    height: 1rem;
                    border-radius: 50%;
                    background-color: #ffa500"></div>`;
      }

      return `<div style="
                    position: relative;
                    top: 4px;
                    width: 1rem;
                    height: 1rem;
                    border-radius: 50%;
                    background-color: #f00"></div>`;
    }

    function onActionHandler(type: string, id: number, categoryId: number) {
      switch (type.toUpperCase()) {
        case 'VIEW':
          return emit('actionView', id);
        case 'DELETE':
          return emit('actionDelete', {
            id, categoryId
          });
      }
    }

    return {
      tableHeadings,
      tableData,
      dataTableLength,
      hasOwn,
      iterates,
      onActionHandler,
    };
  }
})
