import httpClient from "@/api/httpClient";

const END_POINT = '/categories';
const PRACTICE_END_POINT = '/practices'

/**
 * show all categories
 */
const categories = () => httpClient.get(
    END_POINT
);

const createPractice = (categoryId: number, label: string) => httpClient.post(
    END_POINT + '/' + categoryId + PRACTICE_END_POINT,
    { label }
);

export {
    categories,
    createPractice
};