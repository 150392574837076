<template>
  <teleport to="body">
    <div
      id="staticBackdrop"
      class="modal fade "
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div id="modal" class="modal-dialog modal-dialog-centered" :style="large && {maxWidth: '1000px'}">
        <div class="modal-content">
          <div class="modal-header">
            <h5
              id="staticBackdropLabel"
              class="modal-title c-blue fw-bolder text-uppercase"
            >
              {{ title }}
            </h5>
            <button
              type="button"
              class="close-modal btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
            </button>
          </div>
          <div class="modal-body">
            <slot />
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
export default {
  name: "Modal",
  props: {
    title: String,
    large: {
      type: Boolean,
      required: false,
      default: false,
    }
  },

}
</script>

<style scoped>
.modal-dialog {
  max-width: 800px;
  margin: 1.75rem auto;
}

.modal-body {
  padding: 1rem 2rem;
}
</style>