import { defineComponent } from 'vue';

export const spinnerIsLoading = defineComponent({
    data() {
        return {
            isLoading: false as boolean,
        }
    },
    methods: {
        startLoading(): void {
            this.isLoading = true;
        },
        stopLoading(): void {
            this.isLoading = false;
        },
        canShow(): boolean {
            return this.isLoading;
        }
    }
})