<template>
  <div class="text-center p-2">
    <div v-if="error">
      <AlertError :error="error" />
    </div>
    <small>
      Per <strong>creare</strong> una nuova pratica, procedi compilando i campi sottostanti: <br />
      <strong>assegna un nome</strong> alla tua pratica e <strong>seleziona una categoria</strong> pertinente.
    </small>
  </div>
  <form class="mt-4" v-if="!loading">
    <div class="mb-4">
      <label
        for="name"
        class="form-label c-blue fw-bold text-uppercase ms-3"
      >
        Nome Pratica
      </label>
      <input
        id="name"
        type="text"
        class="form-control"
        v-model="data.label"
      />
    </div>
    <div class="mb-3">
      <label
        for="category"
        class="form-label c-blue fw-bold text-uppercase ms-3"
      >
        Seleziona una Categoria
      </label>
      <select
        id="category"
        class="form-select"
        aria-label="Default select example"
        v-model="data.categoryId"
      >
        <option
          selected
          disabled
          :value="0"
        >
          Seleziona una categoria
        </option>
        <option
          v-for="category in categories"
          :key="category.id"
          :value="category.id"
        >
          {{ category.name }}
        </option>
      </select>
    </div>
    <div class="modal-footer mt-5">
      <button
          type="button"
          class="btn bg-danger"
          data-bs-dismiss="modal"
      >
        Chiudi
      </button>
      <button
          type="button"
          class="btn bg-blue"
          :class="required && 'disabled'"
          @click="validateCreation"
      >
        Salva
      </button>
    </div>
  </form>
  <div class="spinner-container">
    <SpinnerBlue :loading="loading" />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { Category } from "@/types/Category";
import AlertError from "@/components/alerts/AlertError.vue";
import SpinnerBlue from "@/components/spinners/SpinnerBlue.vue";

export default defineComponent({
  name: "PracticeForm",
  components: {SpinnerBlue, AlertError},
  emits: ['submitData'],
  props: {
    loading: Boolean as PropType<boolean>,
    categories: Array as PropType<Category[]>,
  },
  data() {
    return {
      data: {
        label: '' as string,
        categoryId: 0 as number, // selected category
      },
      error: '' as string,
    }
  },
  methods: {
    validateCreation() {
      this.error = '';

      if (this.required) return;

      if (this.data.label.length <= 5 || this.data.label.length > 100) {
        this.error = 'Il nome della pratica deve contenere almeno 5 caratteri e massimo 100';

        return;
      }

      this.emitPracticeEvent();
      this.resetFields();
    },
    emitPracticeEvent() {
      this.$emit('submitData', this.data);
    },
    resetFields() {
      this.data.label = '';
      this.data.categoryId = 0;
    }
  },
  computed: {
    required(): boolean {
      return !(this.data.label.length && this.data.categoryId > 0);
    }
  }
})
</script>

<style scoped>
  .form-select,
  .form-control {
    border-radius: 22px;
  }

  strong {
    color: #173759;
  }

  .spinner-container {
    position: relative;
    left: 50%;
  }
</style>