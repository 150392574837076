import httpClient from "@/api/httpClient";

const END_POINT = '/practices/';
const DOCUMENTS_END_POINT = '/documents';
const CATEGORY_END_POINT = '/categories/';

const paginateDocuments = (practiceId: number) => httpClient.get(
  END_POINT + practiceId + DOCUMENTS_END_POINT
);

const deletePractice = (categoryId: number, practiceId: number) => httpClient.delete(
    CATEGORY_END_POINT + categoryId + END_POINT + practiceId
);

const showPractice = (practiceId: number) => httpClient.get(
    END_POINT + practiceId
);

const forceDeletePractice = (practiceId: number) => httpClient.delete(
    END_POINT + practiceId
);

const trashed = (page: number = 1) => httpClient.get(
    END_POINT + 'archived?=' + page
);

const closePracticeById = (id: number) => httpClient.get(
    END_POINT + id + '/close'
);

export {
    paginateDocuments,
    deletePractice,
    showPractice,
    forceDeletePractice,
    trashed,
    closePracticeById
}