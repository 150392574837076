export enum FilterType {
    None = -1,
    Category = 0,
    Status = 1,
    Year = 2,
}

export enum StatusType {
    Started = 'Started',
    Completed = 'Completed',
    Processing = 'Processing',
}