
import { defineComponent, PropType } from 'vue';
import { Category} from "@/types/Category";
import { FilterType, StatusType } from "@/types/PracticeFilterEnum";

export default defineComponent({
  name: 'PracticeFilters',
  emits: ['filterPractices'],
  props: {
    categories: Array as PropType<Category[]>,
  },
  setup(props, { emit }) {
    const status = StatusType;

    function filterPractices (filter: FilterType, resource: number|string) {
      emit('filterPractices', {
        filter,
        id: resource,
      });
    }

    return {
      status,
      filterPractices
    }
  }
})
