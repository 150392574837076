<template>
  <div class="position-relative gray p-5">
    <div v-if="!loading">
      <div class="pb-4 mb-2">
        <slot />
      </div>
      <div class="table-responsive">
        <table v-if="tableHeadings" class="table mb-0">
          <thead>
          <tr v-if="tableHeadings && dataTableLength">
            <th scope="col">Azioni</th>
            <th
                v-for="(_, key) in tableHeadings.value"
                :key="key"
                scope="col"
            >
            <span
                v-if="hasOwn(tableHeadings.value, key)"
            >
              {{ tableHeadings.value[key] }}
            </span>
            </th>
          </tr>
          </thead>
          <tbody v-if="tableData">
          <tr
            v-for="(item, key) in tableData"
            :key="item.title"
          >
            <td>
              <i
                class="bi bi-pencil text-warning me-3"
                @click="onActionHandler('VIEW', item.id, item.category_id)"
              ></i>
              <i
                  class="bi bi-trash"
                  @click="onActionHandler('DELETE', item.id, item.category_id)"
              ></i>
            </td>
            <td
              class="truncate"
              v-for="(element, index) in iterates(key)" :key="index"
              v-html="element"
            >
            </td>
          </tr>
          </tbody>
          <small v-if="!dataTableLength">
            Non ci sono pratiche da visualizzare.
          </small>
        </table>
      </div>
    </div>
    <div class="position-absolute center">
      <SpinnerBlue :loading="loading" />
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  reactive,
  computed,
  PropType,
  onBeforeUpdate,
} from 'vue';
import { Practice } from "@/types/Practice";
import SpinnerBlue from "@/components/spinners/SpinnerBlue.vue";

export default defineComponent({
  name: "Table",
  components: { SpinnerBlue },
  emits: [
    'actionView',
    'actionDelete',
  ],
  props: {
    headings: {
      type: Object,
      required: true
    },
    data: {
      type: Array as PropType<Practice[]>,
      required: true,
    },
    loading: Boolean as PropType<boolean>
  },
  setup(props, { emit }) {
    let tableHeadings = reactive<any>({});
    let tableData = ref<Practice[]>([]);
    let dataTableLength = computed(() => tableData.value.length);

    onBeforeUpdate(() => {
      tableHeadings.value = props.headings;
      tableData.value = props.data;
    });

    function hasOwn(object: Object, key: string): boolean {
      if (!object as Object instanceof Object) return false;

      return object.hasOwnProperty(key);
    }

    function iterates(index: number) {
      const headingKeys = Object.keys(tableHeadings.value);

      const data = headingKeys.map((key: string) => {
        /* @ts-ignore */
        return checkSpecialTypes(key, tableData.value[index][key]);
      });

      if (data.length !== headingKeys.length) {
        console.log('[ERROR] check');

        return [];
      }

      return data;
    }

    function checkSpecialTypes(key: string, element: string) {
      switch (key) {
        case 'email_verified_at':
          if (!element) return 'non verificata';

          return new Date(element).toLocaleDateString();
        case 'created_at':
        case 'updated_at':
          return new Date(element).toLocaleDateString();
        case 'status':
          return checkStatus(element);
      }

      return element
    }

    function checkStatus(status: string) {
      if (status.toUpperCase() === 'STARTED') {
        return `<div style="
                  position: relative;
                  top: 4px;
                  width: 1rem;
                  height: 1rem;
                  border-radius: 50%;
                  background-color: #f00"></div>`;
      }

      if (status.toUpperCase() === 'COMPLETED') {
        return `<div style="
                    position: relative;
                    top: 4px;
                    width: 1rem;
                    height: 1rem;
                    border-radius: 50%;
                    background-color: #0ff000"></div>`;
      }

      if (status.toUpperCase() === 'PROCESSING') {
        return `<div style="
                    position: relative;
                    top: 4px;
                    width: 1rem;
                    height: 1rem;
                    border-radius: 50%;
                    background-color: #ffa500"></div>`;
      }

      return `<div style="
                    position: relative;
                    top: 4px;
                    width: 1rem;
                    height: 1rem;
                    border-radius: 50%;
                    background-color: #f00"></div>`;
    }

    function onActionHandler(type: string, id: number, categoryId: number) {
      switch (type.toUpperCase()) {
        case 'VIEW':
          return emit('actionView', id);
        case 'DELETE':
          return emit('actionDelete', {
            id, categoryId
          });
      }
    }

    return {
      tableHeadings,
      tableData,
      dataTableLength,
      hasOwn,
      iterates,
      onActionHandler,
    };
  }
})
</script>

<style scoped>
  .gray {
    background-color: #f4f4f4;
    border-radius: 20px;
    min-height: 625px;
  }

  .table {
    border-radius: 22px;
    padding: 20px 30px;
  }

  .table>:not(:last-child)>:last-child>* {
    border-bottom: 0;
    color: #173759;
    text-transform: uppercase;
  }

  .status {
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
    padding: 0.2rem;
  }

  .status.green {
    color: red;
  }

  i.bi {
    font-size: 1.35rem;
    cursor: pointer;
  }

  i.bi-eye {
    color: #173759;
  }

  i.bi-trash {
    color: #ff0000;
  }
</style>