import httpClient from '@/api/httpClient';
import { UserDetail } from "@/types/User";
import {StatusType} from "@/types/PracticeFilterEnum";

const END_POINT = '/users/';
const PAGINATE_END_POINT = '/users?page=';
const DETAILS_END_POINT = '/detail';
const PRACTICES_END_POINT = '/practices?page=';
const CATEGORY_END_POINT = '/categories/';
const STATUS_END_POINT = '/status/';

const paginateUsers = (page = 1) => httpClient.get(
    PAGINATE_END_POINT + page
);

const details = (userId: number) => httpClient.get(
    END_POINT + userId + DETAILS_END_POINT
);

const createDetails = (userId: number, data: UserDetail) => httpClient.post(
    END_POINT + userId + DETAILS_END_POINT,
    { ...data }
);

const updateDetails = (userId: number, data: UserDetail) => httpClient.patch(
    END_POINT + userId + DETAILS_END_POINT,
    { ...data }
);

const practices = (userId: number, pageNumber: number = 1) => httpClient.get(
    END_POINT + userId + PRACTICES_END_POINT + pageNumber
);

const practicesByCategory = (categoryId: number, pageNumber = 1, userId: number|null) => httpClient.get(
    CATEGORY_END_POINT + categoryId + END_POINT + userId + PRACTICES_END_POINT + pageNumber
);

const practicesByStatus = (status: StatusType, pageNumber = 1, userId: number|null) => httpClient.get(
    STATUS_END_POINT + status + END_POINT + userId + PRACTICES_END_POINT + pageNumber
);

const searchPractices = (name: string, userId: number|null) => httpClient.post(
    END_POINT + userId + '/practices/' + 'search',
    { name }
);

const userResources = () => httpClient.get(
    END_POINT + 'resources/stats'
);

const userFullResource = (userId: number) => httpClient.get(
    END_POINT + userId + '/info'
)

const allUsers = () => httpClient.get(
    END_POINT + 'all'
)

export {
    paginateUsers,
    details,
    createDetails,
    updateDetails,
    practices,
    practicesByCategory,
    practicesByStatus,
    searchPractices,
    userResources,
    userFullResource,
    allUsers
};