
import { defineComponent, PropType } from 'vue';
import { Category } from "@/types/Category";
import AlertError from "@/components/alerts/AlertError.vue";
import SpinnerBlue from "@/components/spinners/SpinnerBlue.vue";

export default defineComponent({
  name: "PracticeForm",
  components: {SpinnerBlue, AlertError},
  emits: ['submitData'],
  props: {
    loading: Boolean as PropType<boolean>,
    categories: Array as PropType<Category[]>,
  },
  data() {
    return {
      data: {
        label: '' as string,
        categoryId: 0 as number, // selected category
      },
      error: '' as string,
    }
  },
  methods: {
    validateCreation() {
      this.error = '';

      if (this.required) return;

      if (this.data.label.length <= 5 || this.data.label.length > 100) {
        this.error = 'Il nome della pratica deve contenere almeno 5 caratteri e massimo 100';

        return;
      }

      this.emitPracticeEvent();
      this.resetFields();
    },
    emitPracticeEvent() {
      this.$emit('submitData', this.data);
    },
    resetFields() {
      this.data.label = '';
      this.data.categoryId = 0;
    }
  },
  computed: {
    required(): boolean {
      return !(this.data.label.length && this.data.categoryId > 0);
    }
  }
})
