<template>
  <div v-if="error" class="alert alert-danger" role="alert">
    {{ error }}
  </div>
</template>

<script>
export default {
  name: "AlertError",
  props: ["error"],
}
</script>

<style scoped>
  .alert.alert-danger {
    padding: 0.6rem;
    font-size: 0.9rem;
    border-radius: 15px;
  }
</style>