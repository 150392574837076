<template>
  <div v-if="message" class="alert alert-primary" role="alert">
    {{ message }}
  </div>
</template>

<script>
export default {
  name: "AlertSuccess",
  props: ["message"],
}
</script>

<style scoped>
  .alert.alert-primary {
    padding: 0.6rem;
    font-size: 0.9rem;
    color: #fff;
    background-color: #173759;
    border-color: #173759;
    border-radius: 15px;
  }
</style>