<template>
  <label class="c-gray pe-3">
    Filtra:
  </label>
  <div class="dropdown p-2">
    <button class="btn bg-blue dropdown-toggle" type="button" id="categories" data-bs-toggle="dropdown" aria-expanded="false">
      Categoria
    </button>
    <ul class="dropdown-menu" aria-labelledby="categories">
      <li v-for="category in categories" :key="category.id">
        <a @click="filterPractices(0, category.id)" class="dropdown-item" href="#">
          {{ category.name }}
        </a>
      </li>
    </ul>
  </div>
  <div class="dropdown p-3">
    <button class="btn bg-blue dropdown-toggle" type="button" id="status" data-bs-toggle="dropdown" aria-expanded="true">
      Stato
    </button>
    <ul class="dropdown-menu" aria-labelledby="status">
      <li><a @click="filterPractices(1, status.Started)" class="dropdown-item" href="#">Iniziata</a></li>
      <li><a @click="filterPractices(1, status.Processing)" class="dropdown-item" href="#">In Lavorazione</a></li>
      <li><a @click="filterPractices(1, status.Completed)" class="dropdown-item" href="#">Completata</a></li>
    </ul>
  </div>
  <div class="dropdown p-3 p-md-0">
    <button class="btn bg-blue dropdown-toggle" type="button" id="year" data-bs-toggle="dropdown" aria-expanded="true">
      Anno
    </button>
    <ul class="dropdown-menu" aria-labelledby="year">
      <li><a class="dropdown-item" href="#">2021</a></li>
    </ul>
  </div>
  <div class="px-3">
    <button class="btn bg-blue" @click="filterPractices(-1, -1)">
      Reset
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { Category} from "@/types/Category";
import { FilterType, StatusType } from "@/types/PracticeFilterEnum";

export default defineComponent({
  name: 'PracticeFilters',
  emits: ['filterPractices'],
  props: {
    categories: Array as PropType<Category[]>,
  },
  setup(props, { emit }) {
    const status = StatusType;

    function filterPractices (filter: FilterType, resource: number|string) {
      emit('filterPractices', {
        filter,
        id: resource,
      });
    }

    return {
      status,
      filterPractices
    }
  }
})
</script>

<style scoped>
  button {
    border-radius: 22px;
    font-size: 0.84rem;
  }
</style>