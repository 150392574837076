<template>
  <div v-if="loading" class="spinner-border c-blue" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</template>

<script>
export default {
  name: "SpinnerBlue",
  props: ["loading"]
}
</script>

<style scoped>

</style>