<template>
  <div class="practice-legend mb-4">
    <div class="header bg-blue p-2">
      <p class="text-uppercase text-center mb-0 text-white fw-bolder">
        Legenda
      </p>
    </div>
    <div class="body h-50 d-sm-flex justify-content-around align-items-center">
      <div class="d-flex justify-content-center my-3 my-sm-0">
        <div class="dot red ms-2 me-1"></div>
        <p class="description mb-0">Apertura Pratica</p>
      </div>
      <div class="d-flex justify-content-center my-3 my-sm-0">
        <div class="dot orange ms-2 me-1"></div>
        <p class="description mb-0">caricamento documenti</p>
      </div>
      <div class="d-flex justify-content-center my-3 my-sm-0">
        <div class="dot green ms-2 me-1"></div>
        <p class="description mb-0">pratica elaborata</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PracticeLegend"
}
</script>

<style scoped>
  .practice-legend {
    position: relative;
    max-width: 500px;
    min-height: 80px;
    border: 1px solid #173759;
  }

  .description {
    text-transform: uppercase;
    font-size: 0.8rem;
  }

  .dot {
    position: relative;
    top: -2px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }

  .dot.red {
    background-color: #f00;
  }

  .dot.orange {
    background-color: #ffa500;
  }

  .dot.green {
    background-color: #01B301;
  }

  @media screen and (min-width: 600px) {
    .practice-legend {
      height: 80px;
      min-height: unset;
      width: 576px;
      max-width: unset;
      top: 0;
      left: calc(100% - 576px);
    }
  }
</style>